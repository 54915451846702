import { Block, Flex, Text } from '@actovos-consulting-group/ui-core';
import { Link } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';

const CenterContainer = styled(Flex)`
  text-align: center;
  position: relative;
  top: -100px;

  img {
    width: 200px;
  }
`;

const HomeLink = styled(Link)(
  ({ theme }) => css`
    font-size: 1.3rem;
    font-weight: 600;
    border: 3px solid ${theme.colors.border};
    color: ${theme.colors.lightText};
    background-color: white;
    padding: 10px 50px;
    text-decoration: none;
    display: block;
    border-radius: 25px;
  `,
);

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Flex
      justifyContent="center"
      alignItems="center"
      position="relative"
      minHeight={{ _: 750, xl: 1000 }}
      height="100%"
    >
      <CenterContainer p={3} flexDirection="column" alignItems="center">
        <Text
          fontSize={{ _: '2rem', xs: '3rem', xl: '4rem' }}
          textAlign="center"
          mb={2}
        >
          Somehow you have gotten lost
        </Text>
        <Text fontSize={{ _: '1rem', xl: '2rem' }} textAlign="center" mb={4}>
          Here are some helpful links to get you back on track.
        </Text>
        <Block mt={4}>
          <HomeLink to="/">Go Home</HomeLink>
        </Block>
      </CenterContainer>
    </Flex>
  </Layout>
);

export default NotFoundPage;
